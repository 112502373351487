import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'

import Page from 'layouts/Page'
import SEO from 'components/SEO'

const container = css`
    color: #fff;
    display: grid;
    grid-template-rows: 30% 1fr auto;
    grid-template-columns: 1fr auto;
    a {
        color: inherit;
    }
    a:hover {
        text-decoration: underline;
    }
`

const header = css`
    grid-row: 2;
    grid-column: 1;
    margin: 0 auto;
`

const NotFoundPage = () => (
    <Page title="Not found">
        <div className={container}>
            <section className={header}>
                <h1>404</h1>
                <p>Not implemented... yet.</p>
                <Link to="/">Go back</Link>
            </section>
        </div>
    </Page>
)

export default NotFoundPage
